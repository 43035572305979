import { Component, PropSync, Vue } from "vue-property-decorator";

@Component<APLightbox>({
  components: {}
})
export default class APLightbox extends Vue {
  private LBdoubleClick = false;
  @PropSync("message", { type: String }) syncedMessage!: string;
  @PropSync("showLB", { type: Boolean }) syncedShow!: boolean;
  @PropSync("confirmLink", { type: String }) syncedConfirmLink!: string;
  @PropSync("confirmWord", { type: String }) syncedConfirm!: string;
  private confirm = '確認';

  public created() {
    this.confirm = (this.syncedConfirm != '' && this.syncedConfirm !== undefined) ? this.syncedConfirm : this.confirm;
    // this.$parent.$on("openAPLightbox", (open: boolean, type: number, sqid: string) => {
    //   this.open = open;
    //   this.type = type;
    //   this.sqid = sqid;
    // });
  }

  public closeLightbox() {
    if (!this.LBdoubleClick) {
      this.LBdoubleClick = true;
      this.$emit("closeAPLightbox");
      if (this.syncedConfirmLink !== undefined && this.syncedConfirmLink != '') {
        this.$router.push(this.syncedConfirmLink);
      }  
      this.LBdoubleClick = false;
    }
  }
}