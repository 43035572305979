import { Component, PropSync, Vue } from "vue-property-decorator";

@Component<AddStudent>({
  components: {}
})
export default class AddStudent extends Vue {
  @PropSync("showLB", { type: Boolean }) syncedShow!: boolean;
  private name = '';
  private phone = '';
  @PropSync("studentList", { type: Array }) syncedStudentList!: [];

  private stDoubleClick = false;
  private student = [];
  private errorMsg = '';

  private closeAddStudentLightbox() {
    if (!this.stDoubleClick) {
      this.stDoubleClick = true;
      this.$emit("closeLB");
      this.stDoubleClick = false;
    }
  }

  public getAddStudentList() {
    if (!this.stDoubleClick) {
      this.stDoubleClick = true;
      this.$emit("getList", this.name, this.phone);
      this.stDoubleClick = false;
    }
  }

  public addStudent() {
    if (this.student.length === 0) {
      this.errorMsg = '至少選擇一個';
      return;
    }

    if (!this.stDoubleClick) {
      this.stDoubleClick = true;
      this.$emit("submitLB", this.student);
      this.stDoubleClick = false;
    }
  }
}