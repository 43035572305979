/* eslint-disable @typescript-eslint/camelcase */
import { Component, Prop, Vue } from "vue-property-decorator";

import tinymce from "tinymce";
import Editor from "@tinymce/tinymce-vue";

import 'tinymce/themes/silver/theme.min.js'
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/media";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/preview";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/hr";
import "tinymce/plugins/paste";
import "tinymce/plugins/codesample";
import 'tinymce/icons/default/icons'
import "tinymce/plugins/autolink";
import "tinymce/plugins/charmap";
import "tinymce/plugins/emoticons";

import { Network } from "@/network";


@Component({ name: "TinymceEditer", components: { Editor } })
export default class extends Vue {
  //设置prop id
  @Prop({ default: "vue-tinymce-" + +new Date() }) id!: string;

  //默认高度
  @Prop({ default: 600 }) height!: number;

  @Prop({ default: '' }) content!: string

  // 富文本框值
  private value = '';

  //富文本框init配置
  private get init() {
    return {
      //同個頁面只能顯示一個 測試
      inline: false,

      selector: "#" + this.id, //富文本编辑器的id
      language: "zh_TW", //语言
      language_url: "/assets/tinymce/langs/zh_TW.js", //语言包
      skin_url: "/assets/tinymce/skins/ui/oxide", //编辑器需要一个skin才能正常工作，所以要设置一个skin_url指向之前复制出来的skin文件
      content_css: `/assets/tinymce/skins/content/default/content.css`, //css路徑
      // emoticons_database_url: "/assets/tinymce/emojis.js", // 更改表情包的url, 不然默認指定在public/js/plugins/emoticons/js/emojis.js
      menubar: "file edit view insert format tools", //菜单条
      toolbar_drawer: false, //将第一行放不下的工具栏按钮缩进抽屉（3个点的图标）里。
      // plugins:
      //     'link lists image code table colorpicker textcolor wordcount contextmenu  media table fullscreen preview pagebreak insertdatetime hr paste codesample emoticons', //插件

      //移除 textcolor contextmenu colorpicker
      plugins:
        "link lists image code table wordcount table fullscreen preview pagebreak insertdatetime hr paste codesample autolink charmap", //插件
      toolbar:
        "bold italic underline strikethrough  | fontselect | fontsizeselect | formatselect  | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | removeformat| undo redo | link unlink image insertdatetime table  hr pagebreak codesample | code fullscreen preview charmap", //工具条
      // font_formats: 'Arial=arial,helvetica,sans-serif; 宋体=SimSun;  微软雅黑=Microsoft Yahei; Impact=impact,chicago;', //字体 //有點少乾脆註解掉
      fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px 48px 64px 72px", //文字大小
      height: this.height, //高度
      branding: false, //水印
      elementpath: false, //底部元素路径
      paste_data_images: true, //允许粘贴图片
      //初始化前执行
      // setup: (editor: any) => {}, //有錯
      //实例化执行
      // init_instance_callback: (editor: any) => {
      //   this.content && editor.setContent(this.content);

      //   //this.hasInit = true
      //   editor.on("NodeChange Change KeyUp SetContent", () => {
      //     //this.hasChange = true
      //     this.content = editor.getContent();
      //   });
      // },

      // //视频设置回调
      // video_template_callback: (data: any) => {
      //   return `<video width="
      //               ${data.width} " height="${data.height}"
      //               ${data.poster ? 'poster="' + data.poster + '"' : ""}
      //               controls="controls">
      //               <source src="${data.source}"/>
      //           </video>`;
      // },

      //圖片上傳
      images_upload_handler: (
        blobInfo: any,
        success: Function,
        failure: Function
      ) => {
        const formData = new FormData();
        // console.log(blobInfo.filename());
        formData.append("img", blobInfo.blob());
        formData.append("token", window.localStorage.getItem('adminToken') as string);

        Network.uploadFile("/api/admin/tinymce/upload", formData)
          .then(response => {
            const data = response.data as { [key: string]: string };

            if (data.img) {
              success(data.img);
            } else {
              console.log(response);
              failure("error");
            }
          })
          .catch(error => {
            console.log(error);
            failure("error");
          });

        // //images_upload_handler不能加async 沒辦法用await
        // const responseData = await ProductModel.add(data) as responseData;
      }
    };
  }

  mounted() {
    tinymce.init({})
    this.value = this.content
  }

  onEditorInput(e: any) {
    this.$emit('input', this.value)
  }

  setContent(str: any) {
    this.value = str
  }
}
