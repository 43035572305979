import { Component, Vue } from "vue-property-decorator";

@Component<ConfirmDelete>({
    components: {}
})
export default class ConfirmDelete extends Vue {
    private deleteDoubleClick = false;
    private title = '';
    private open = false;

    public created() {
      this.$parent.$on("openConfirmDelete", (open: boolean, title: string) => {
        this.title = (title) ? title : '確定要刪除?';
        this.open = open;
      });
      this.$parent.$on("closeConfirmDelete", (open: boolean) => {
        this.open = open;
      });
    }

    public closeConfirmDeleteLightbox() {
      this.open = false;
    }
    
    private submitDelete() {
      if (!this.deleteDoubleClick) {
        this.deleteDoubleClick = true;
        this.$emit("submitDelete");
        this.deleteDoubleClick = false;
      }
    }
  
}