import { Component, Vue } from "vue-property-decorator";

@Component<APQuizHint>({
  components: {}
})
export default class APQuizHint extends Vue {
  private quizDoubleClick = false;
  private num = 0;
  private open = false;

  public created() {
    this.$parent.$on("openQuizHint", (open: boolean, num: number) => {
      this.open = open;
      this.num = num;
    });
  }

  private submitQuiz() {
    if (!this.quizDoubleClick) {
      this.quizDoubleClick = true;
      this.$emit("submitQuiz");
      this.open = false;
    }
  }

}