import { Component, PropSync, Vue } from "vue-property-decorator";

@Component<Lightbox>({
  components: {}
})
export default class Lightbox extends Vue {
  private LBdoubleClick = false;
  @PropSync("message", { type: String }) syncedMessage!: string;
  @PropSync("openLB", { type: Boolean }) syncedOpen!: boolean;

  private closeLightbox() {
    if (!this.LBdoubleClick) {
      this.LBdoubleClick = true;
      this.$emit("closeLightbox");
      this.LBdoubleClick = false;
    }
  }
}