import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, Matches, validate } from "class-validator";

export class PwdForm{
  @IsDefined() @IsNotEmpty({ message: "*必填" }) @Matches(/^(?=.*[a-z])(?=.*[A-Z])[A-Za-z].{6,}$/, {message: '*請輸入至少6位包含大小寫英文字母的密碼'}) newPwd = '';
  @IsDefined() @IsNotEmpty({ message: "*必填" }) confirmPwd = '';
}

@Component<ResetPwd>({
    components: {}
})
export default class ResetPwd extends Vue {
    private open = false;
    private doubleClick = false; 
    private pwdForm = new PwdForm();
    private acc = '';
    private errorMsgMap: { [key: string]: string } = {
      newPwd: '',
      confirmPwd: '',
    };

    public created() {
      this.$parent.$on("openResetPwd", (open: boolean, acc: string) => {
        this.acc = acc;
        this.open = open;
      });
    }

    public async closeResetPwdLightbox() {
      this.open = false;
    }
    
    private submitResetPwd() {
        validate(this.pwdForm, { skipMissingProperties : true }).then(
            async result => {
                if(result.length === 0){
                    if(this.pwdForm.confirmPwd != this.pwdForm.newPwd){
                        this.errorMsgMap['confirmPwd'] = '*密碼錯誤，與上方密碼不符';
                    }else{
                        if(this.pwdForm.newPwd == this.acc){
                            this.errorMsgMap['confirmPwd'] = '*密碼不應與帳號相同';
                        }else{
                          if(!this.doubleClick){
                              this.doubleClick = true;
                              this.$emit("submitResetPwd", this.pwdForm.newPwd);
                              this.open = false;
                          }
                        }
                    }
                }else{
                
                    this.errorMsgMap = { //先清空error message
                        newPwd: '',
                        confirmPwd: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                }
            }
        )
    }
}