import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, validate } from "class-validator";

export class Form{
  @IsDefined() @IsNotEmpty({ message: "*必填" }) note = '';
}

@Component<Note>({
    components: {}
})
export default class Note extends Vue {
    private open = false;
    private doubleClick = false; 
    private form = new Form();
    private errorMsgMap: { [key: string]: string } = {
      note: '',
    };

    public created() {
      this.$parent.$on("openNote", (open: boolean, note: string) => {
        this.form.note = note;
        this.open = open;
      });
    }

    public async closeNoteLightbox() {
        this.open = false;
    }
    
    private submitNote() {
        validate(this.form, { skipMissingProperties : true }).then(
            async result => {
                if(result.length === 0){
                    if(!this.doubleClick){
                        this.doubleClick = true;
                        this.$emit("submitNote", this.form.note);
                        this.open = false;
                    }
                }else{
                
                    this.errorMsgMap = { //先清空error message
                        note: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                }
            }
        )
    }
}