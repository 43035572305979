import { Component, PropSync, Vue } from "vue-property-decorator";

@Component<APAudio>({
  components: {}
})
export default class APAudio extends Vue {
  private LBdoubleClick = false;
  @PropSync("showLB", { type: Boolean }) syncedShow!: boolean;
  @PropSync("errorMsg", { type: String }) syncedErrorMsg!: string;
  private audio = '';
  private errMsg = '';

  private previewfile(event: any) {
    const file = event.target.files[0];

    if (file) { //若有上傳

      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.audio = file.name;
      };
      reader.readAsDataURL(file);
    }
  }

  private uploadAudio() {
    if (!this.LBdoubleClick) {
      this.LBdoubleClick = true;

      if (this.audio == '') {
        this.errMsg = '請先上傳音檔';
        this.LBdoubleClick = false;
        return;
      }

      const formobj: HTMLFormElement = document.getElementById('audioForm') as HTMLFormElement;
      const data = new FormData(formobj); //創建空的Formdata object
      
      this.$emit("uploadAudio", data);
      this.LBdoubleClick = false;
    }
  }

  private closeAudioLightbox() {
    if (!this.LBdoubleClick) {
      this.LBdoubleClick = true;
      this.$emit("closeAudioLB");
      this.LBdoubleClick = false;
    }
  }
}